import React from 'react';
import Seo from '../components/config/Seo';
import Faq from '../components/shared/sections/faq-section';
import StepSection from '../components/shared/sections/step-section';
import CallToActionSection from '../components/shared/sections/call-to-action-section';
import { graphql } from 'gatsby';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import PageSection from '../components/shared/sections/page-section';
import HeaderSection from '../components/shared/sections/header-section';
import useGetProcessPageTranslation from '../hooks/useGetProcessPageTranslation';

const Process = ({ data }) => {
  const {
    pageTitle,
    headerSection,
    pageSection,
    stepSection,
    faq,
    pageSectionTwo,
    callToActionSection,
  } = useGetProcessPageTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down('sm')
  );

  return (
    <>
      <Seo title={pageTitle} />
      <HeaderSection
        title={headerSection.title}
        description={headerSection.description}
      />
      <PageSection
        title={pageSection.title}
        description={pageSection.description}
        gatsbyImg={{
          fluid:
            data.pageSections.edges[0].node.childImageSharp
              .fluid,
          width: smallScreen ? '100%' : 580,
          height: smallScreen ? '100%' : 339,
          objectFit: 'none',
          objectPosition: smallScreen ? 'bottom' : 'center',
          imgStyle: {
            width: smallScreen ? '100%' : 580,
            height: smallScreen ? '100%' : 332,
            filter:
              !smallScreen &&
              'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15))',
          },
        }}
        showCircles={false}
        isLight
        testimony={{
          description: pageSection.testimony.description,
          author: pageSection.testimony.author,
          company: pageSection.testimony.company,
        }}
      />
      <StepSection
        title={stepSection.title}
        steps={[...stepSection.steps]}
        extraSteps={[...stepSection.extraSteps]}
      />
      <Faq
        title={faq.title}
        questions={
          data.allContentfulProcessFaqList.edges[0].node
            .faqItems
        }
      />
      <PageSection
        title={pageSectionTwo.title}
        description={pageSectionTwo.description}
        buttonText={pageSectionTwo.buttonText}
        gatsbyImg={{
          fluid:
            data.pageSections.edges[1].node.childImageSharp
              .fluid,
          width:
            mediumScreen && !smallScreen
              ? 478
              : smallScreen
              ? 273
              : 560,
          height: smallScreen ? 200 : 288,
          pattern: 'arch',
          objectFit: 'none',
          objectPosition: 'center',
          imgStyle: {
            marginTop: smallScreen ? '' : 10,
            width:
              mediumScreen && !smallScreen
                ? 478
                : smallScreen
                ? 273
                : 570,
            height:
              mediumScreen && !smallScreen
                ? ''
                : smallScreen
                ? 273
                : 282,
            filter:
              'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15))',
          },
        }}
      />
      <CallToActionSection
        title={callToActionSection.title}
        description={callToActionSection.description}
        buttonTitle={callToActionSection.buttonTitle}
      />
    </>
  );
};

export default Process;

export const query = graphql`
  query ProcessPage {
    pageSections: allFile(
      filter: {
        relativePath: { regex: "/^page-sections/process/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    allContentfulProcessFaqList {
      edges {
        node {
          faqItems {
            answer {
              answer
            }
            question
          }
        }
      }
    }
  }
`;
