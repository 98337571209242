import { useIntl } from 'gatsby-plugin-intl';
import { useMemo } from 'react';

const useGetProcessPageTranslation = () => {
  const { formatMessage: t, locale } = useIntl();
  return useMemo(() => {
    if (locale && t) {
      return {
        pageTitle: t({ id: 'pages.process' }),
        headerSection: {
          title: t({ id: 'process.sections.0.title' }),
          description: t({
            id: 'process.sections.0.description',
          }),
        },
        pageSection: {
          title: t({ id: 'process.sections.5.title' }),
          description: t({
            id: 'process.sections.5.description',
          }),
          testimony: {
            description: t({
              id:
                'process.sections.5.testimony.description',
            }),
            author: t({
              id: 'process.sections.5.testimony.author',
            }),
            company: t({
              id: 'process.sections.5.testimony.company',
            }),
          },
        },
        stepSection: {
          title: t({ id: 'process.sections.2.title' }),
          steps: [
            {
              title: t({
                id: 'process.sections.2.steps.0.title',
              }),
              description: t({
                id:
                  'process.sections.2.steps.0.description',
              }),
            },
            {
              title: t({
                id: 'process.sections.2.steps.1.title',
              }),
              description: t({
                id:
                  'process.sections.2.steps.1.description',
              }),
            },
            {
              title: t({
                id: 'process.sections.2.steps.2.title',
              }),
              description: t({
                id:
                  'process.sections.2.steps.2.description',
              }),
            },
          ],
          extraSteps: [
            t({
              id: 'process.sections.2.extraSteps.0',
            }),
            t({
              id: 'process.sections.2.extraSteps.1',
            }),
            t({
              id: 'process.sections.2.extraSteps.2',
            }),
          ],
        },
        faq: {
          title: t({ id: 'process.sections.1.title' }),
        },
        pageSectionTwo: {
          title: t({ id: 'process.sections.4.title' }),
          description: t({
            id: 'process.sections.4.description',
          }),
          buttonText: t({
            id: 'process.sections.4.link',
          }),
        },
        callToActionSection: {
          title: t({ id: 'process.sections.3.title' }),
          description: t({
            id: 'process.sections.3.description',
          }),
          buttonTitle: t({
            id: 'process.sections.3.button',
          }),
        },
      };
    } else {
      return {
        pageTitle: '',
        headerSection: {
          title: '',
          description: '',
        },
        pageSection: {
          title: '',
          description: '',
          testimony: {
            description: '',
            author: '',
            company: '',
          },
        },
        stepSection: {
          title: '',
          steps: [],
          extraSteps: [],
        },
        faq: {
          title: '',
        },
        pageSectionTwo: {
          title: '',
          description: '',
          buttonText: '',
        },
        callToActionSection: {
          title: '',
          description: '',
          buttonTitle: '',
        },
      };
    }
  }, [locale, t]);
};

export default useGetProcessPageTranslation;
